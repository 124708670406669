@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";


.c-type-cta {
    .img-wrapper {
        img {
            width: 100%;
        }
    }

    .text-container {
        .contact {
            margin: 2rem 0;
            padding: 0;
            list-style: none;

            li {
                padding-left: 0;
            }

            .cta-link {
                display: flex;
                align-items: center;

                .svg-icon {
                    margin-right: 0.5rem;
                    /*stroke-width: 6px;*/
                }
            }
        }

        h4+.contact {
            margin: 0 0 1.5rem;
        }

        .tx-powermail {
            margin: 0;


            label {
                white-space: nowrap;
                font-size: 0.9rem;
            }

            @include media-breakpoint-up(xl){
                label {
                    font-size: 1rem;
                }

            }

            .col-xl-9.offset-xl-1 {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 0;
            }

            .form-control {
                background-color: transparent;

                &.powermail_field_error {
                    margin-bottom: 0;
                }
            }

            .powermail-errors-list {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                overflow: hidden;
                clip: rect(0,0,0,0);
                white-space: nowrap;
                border: 0;
            }

        }
    }
}

@include media-breakpoint-up(md) {
    .c-type-cta {
        .text-container {
            header {
                .headline-icon {
                    .svg-icon {
                        stroke-width: 6px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .c-type-cta {
        .cta-container {
            .img-wrapper {
                width: 100%;
                overflow: hidden;

                img {
                    position: absolute;
                    bottom: -3rem;
                    right: 2rem;
                    width: auto;
                }
            }
        }
    }
}


